/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { ThemeProvider } from "@dolthub/react-components";
import "@dolthub/shared-components/style/GetStartedWithDolt.css";
import "@dolthub/shared-components/style/MailingList.css";
import "@dolthub/shared-components/style/Navbar.css";
import "@dolthub/shared-components/style/main.css";
import React from "react";
import Layout from "./src/components/Layout";
import { SearchProvider } from "./src/contexts/search";
import "./src/styles/prism-dolthub.css";

export const wrapRootElement = ({ element }) => (
  <SearchProvider>{element}</SearchProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <Layout {...props}>{element}</Layout>
  </ThemeProvider>
);
