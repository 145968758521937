import { Footer, GetStartedWithDolt, Navbar } from "@dolthub/shared-components";
import React from "react";
import useHasMounted from "../util/useHasMounted";
import useStarCount from "../util/useStarCount";
import * as css from "./Layout.module.css";

type Props = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const hasMounted = useHasMounted();

  return (
    <div>
      {hasMounted && <Nav />}
      <main className={css.siteMain}>{children}</main>
      <div className={css.bottomOuter}>
        <div className={css.blueDiagonal} />
        <div className={css.bottom}>
          <GetStartedWithDolt />
          {hasMounted && <Footer />}
        </div>
      </div>
    </div>
  );
}

// The navbar utilizes css that is injected by javascript in
// @dolthub/react-components, so we need to render it client-side only.
function Nav() {
  const starCount = useStarCount();
  return <Navbar transparentBg darkText githubStarCount={starCount} />;
}
