import { createCustomContext } from "@dolthub/react-contexts";
import { markdownToTxt } from "markdown-to-txt";
import React, { ReactNode, useContext } from "react";

type Blogs = Queries.Maybe<
  ReadonlyArray<Queries.Maybe<Queries.SitePageContextBlogs>>
>;

export type SearchedBlog = Queries.NodeForBlogIndexFragment & {
  longExcerpt: string;
};

type SearchContextType = {
  searchBlogs: SearchedBlog[];
  setSearchBlogs: (b: Blogs) => void;
};

const SearchContext = createCustomContext<SearchContextType>("SearchContext");

type Props = {
  children: ReactNode;
};

export function SearchProvider(props: Props) {
  const [searchBlogs, _setSearchBlogs] = React.useState<SearchedBlog[]>([]);

  const setSearchBlogs = (blogs: Blogs) => {
    const mapped = mapBlogsToIndexNodes(blogs);
    _setSearchBlogs(mapped);
  };

  return (
    <SearchContext.Provider
      value={{
        searchBlogs,
        setSearchBlogs,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}

export function useSearchContext(): SearchContextType {
  return useContext(SearchContext);
}

function mapBlogsToIndexNodes(blogs: Blogs): SearchedBlog[] {
  if (!blogs) return [];
  const mapped: Array<SearchedBlog | undefined> = blogs.map((b) => {
    if (!b?.node) return undefined;
    const { id } = b.node;
    if (!id) return undefined;
    const md = b.node.rawMarkdownBody ?? "";
    // We need to manually convert the raw markdown to text for the `excerpt`
    // and `timeToRead` fields when a search is active until this issue is
    // resolved: https://github.com/gatsbyjs/gatsby/issues/38855
    const mdTxt = markdownToTxt(md.slice(0, 1500));
    const longExcerpt = mdTxt.slice(0, 1000);
    const shortExcerpt = longExcerpt.slice(0, 250);
    const node: SearchedBlog = {
      ...b.node,
      longExcerpt,
      timeToRead: readingTime(md),
      excerptAst: { type: "text", value: `${shortExcerpt}...` },
      id,
    };
    return node;
  });
  return mapped.filter(Boolean) as SearchedBlog[];
}

function readingTime(post: string): number {
  const WORDS_PER_MINUTE = 275;
  // Matches words
  const regex = /\w+/g;
  const wordCount = post.match(regex)?.length ?? 0;

  return Math.ceil(wordCount / WORDS_PER_MINUTE);
}
