import React from "react";

export default function useStarCount(): number {
  const [starCount, setStarCount] = React.useState<number>(16000);
  const graphqlApiUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:9000/graphql"
      : "https://www.dolthub.com/graphql";

  React.useEffect(() => {
    fetch(graphqlApiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        operationName: "doltStarCount",
        query: `
          query doltStarCount {
            doltStarCount {
              count
            }
          }
        `,
      }),
    })
      .then(async (res) => res.json())
      .then(({ data }) => {
        setStarCount(data.doltStarCount.count);
      })
      .catch((err) => console.error(err));
  }, []);

  return starCount;
}
