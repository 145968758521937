import { useEffect, useState } from "react";

// Reference for rendering certain components client-side only:
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export default function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}
